import React from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Footer from "./Footer";
import { ImageViewer } from "react-image-viewer-dv";
import { useTranslation } from "react-i18next";

const Our_gallery = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h2>{t('our_gallery')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gallery-banner.jpg" />
      </section>

      {/* ************************************************
    Partner Logo
    ************************************************ */}
      <section id="gallery">
        <Container>
          <Row>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/1.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/2.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/3.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/4.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/5.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/6.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/7.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/8.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/9.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/10.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/11.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/12.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/13.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/14.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/15.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/16.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/17.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/18.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/19.jpg" />
              </ImageViewer>
            </Col>
            <Col sm={4}>
              <ImageViewer>
              <Image src="images/gallery/20.jpg" />
              </ImageViewer>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Our_gallery;
