import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const About_us = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>PT. XOLARE RCR ENERGY, Tbk.</h6>
                <h2>{t("About_Us")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/about-us-banner.jpg" />
      </section>

      {/* ************************************************
    Who We Are Section
    ************************************************ */}
      <section id="who-we-are">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={5} xl={6}>
              <video src="video/about-video.mp4" type="video/mp4" controls />
            </Col>
            <Col sm={12} md={12} lg={6} xl={5}>
              <div className="content-panel">
                <h6>
                  <i className="bi bi-lightning-fill"></i>
                  {t("Our_About")} <i className="bi bi-lightning-fill"></i>
                </h6>
                <h3>{t("We_Provide_Various_Service")}</h3>
                <p>{t("PT._Xolare_RCR_Energy_XOLARE")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/light-lamp.png" className="light-lamp" />
      </section>

      {/* ************************************************
    Who We Are Section
    ************************************************ */}
      <section id="who-we-are" className="pt-5">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={5}>
              <div className="content-panel mt-5">
                <p>{t("XOLARE_always_grows_and_develops")}</p>
                <p>{t("XOLARE_always_grows_and_develops_second")}</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={5} xl={6}>
              <Image src="images/who-we-are-img2.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        id="quote"
        style={{
          backgroundImage: `url("images/quote-bg.png")`,
        }}
      >
        <Container>
          <Row>
            <Col sm={12}>
              <h4>{t("24_years_Experience")}</h4>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    COMPANY HISTORY
    ************************************************ */}
      <section id="history-timeline">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <h2>{t("Company_History")}</h2>
            </Col>
            <Col sm={12}>
              <div
                id="horizontal-timeline"
                className="timeline horizontal reverse"
              >
                <div className="block">
                  <div className="square up">
                    <p>{t("Investment_Company")}</p>
                  </div>
                  <div className="circle">
                    <p>2014</p>
                  </div>
                  <div className="link"></div>
                  <div className="square down blank"></div>
                </div>
                <div className="block">
                  <div className="square up blank"></div>
                  <div className="circle activated">
                    <p>2016</p>
                  </div>
                  <div className="link"></div>
                  <div className="square down">
                    <p>{t("EPC_Company")}</p>
                  </div>
                </div>
                <div className="block">
                  <div className="square up">
                    <p>{t("PMDN_Company")}</p>
                  </div>
                  <div className="circle">
                    <p>2019</p>
                  </div>
                  <div className="link"></div>
                  <div className="square down blank"></div>
                </div>
                <div className="block">
                  <div className="square up blank"></div>
                  <div className="circle">
                    <p>2020</p>
                  </div>
                  <div className="link"></div>
                  <div className="square down">
                    <p>{t("mechanical_and_electrical")}</p>
                  </div>
                </div>

                <div className="block">
                  <div className="square up">
                    <p>{t("Changed_name_to_PT._Xolare")}</p>
                  </div>
                  <div className="circle">
                    <p>2022</p>
                  </div>
                  <div className="link"></div>
                  <div className="square down blank"></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Our Vision
    ************************************************ */}
      <section id="our-vision">
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className="left-content-panel">
                <h3>{t("vision_mission_title")}</h3>
                <h4>{t("vision_subtitle")}</h4>
                <Image src="images/puzzle.png" width={35} />{" "}
                <p>{t("vision_content")}</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={8} xl={{ span: 7, offset: 1 }} className="px-0">
              <div
                className="mission"
                style={{
                  backgroundImage: `url("images/bg-cover-mission.jpg")`,
                }}
              >
                <span className="mission-title-circle">MISSION</span>
                <ListGroup>
                  <ListGroup.Item>
                    <Image src="images/mission-list-icon.png" />
                    {t("our_mission_list1")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Image src="images/mission-list-icon.png" />
                    {t("our_mission_list2")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Image src="images/mission-list-icon.png" />
                    {t("our_mission_list3")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Image src="images/mission-list-icon.png" />
                    {t("our_mission_list4")}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <Footer></Footer>
    </>
  );
};

export default About_us;
