import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";
const Board_commissioners = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="management-banner">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="position-relative">
              <div className="content-panel">
                <h6>{t('management')}</h6>
                <h2>{t('board_of_commissioners')}</h2>
                
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/mangement-banner.jpg" />
      </section>

      {/* ************************************************
    Member of board
    ************************************************ */}
      <section id="member_of_board">
        <Container>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/sani.png" />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Dr. Dipl.-Ing. Winardi Sani</h5>
                <h6>{t('president_commissioner')}</h6>
                <p>{t('born_in_banyuwangi')} 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="member_of_board" className="gray">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Achmad Alwi, SH.</h5>
                <h6>{t('commissioner')}</h6>
                <p>{t('born_in_kota_agung')}</p>
                <p>{t('sriwijaya_university')}
                </p>
                <p>{t('commissioner_of_the_company')}</p>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/alwi.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="member_of_board">
        <Container>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/eka.png" />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Ramdani Eka Saputra, SE, SH, MM, MH</h5>
                <h6>{t('independent_commissioner')}</h6>
                <p>{t('born_in_tarakan')}</p>
                <p>{t('veteran_vniversity')} 
                </p>
                <p>{t('citra_persada_infrastruktur')}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Board_commissioners;
