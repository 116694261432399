import React from "react";

import Header from "./Header";
import HeroSlider from "./HeroSlider";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

import "bootstrap-icons/font/bootstrap-icons.css";

import Card from "react-bootstrap/Card";
import Footer from "./Footer";

import { useTranslation } from "react-i18next";


const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      <HeroSlider></HeroSlider>

      {/* ************************************************
    About Us
    ************************************************ */}
      <section id="about_us">
        <Container>
          <Row>
            <Col sm={12} md={5} lg={5} xl={5} className="position-relative">
              <Image src="images/about-img.png" />
              <h5><strong>10</strong> {t('years_of_experience')}</h5>
            </Col>
            <Col sm={12} md={6} lg={6} xl={{ span: 6, offset: 1 }}>
              <div className="content-panel">
                <h2>{t('aboutpt_heading')}</h2>
                <p>{t('aboutpt_para_one')}</p>

                <p>{t('aboutpt_para_two')}</p>

                <Link to={"/about-us"} className="btn-readmore">
                  <i className="bi bi-chevron-right"></i>{t('aboutpt_readmorebtn')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    We worked with global largest brands 
    ************************************************ */}
      <section id="brands">
        <Container>
          <Row>
            <Col sm={12}>
              <h4>{t('work_brand_heading')}</h4>

              <Swiper
                slidesPerView={6}
                spaceBetween={10}
                className="brands-slider"
                autoplay={{delay: 2000, disableOnInteraction: false, }}
                modules={[Autoplay]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                }}
              >
                <SwiperSlide>
                  <Image src="images/brand-1.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-3.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-4.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-5.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-6.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-1.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-7.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src="images/brand-8.png" />
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Our News & Updates
    ************************************************ */}
      <section id="news-updates">
        <Container>
          <Row>
            <Col sm={6}>
              <h2>{t('news_top_heading')}</h2>
              <Link to={"/our-news"} className="btn-readmore">
                <i className="bi bi-chevron-right"></i>{t('news_view_btn')} </Link>
            </Col>
            
            <Col sm={12}>
            <Swiper slidesPerView={3} spaceBetween={30} navigation={true} modules={[Navigation]} className="news-updates" 
            breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1400: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}>
              
                <SwiperSlide>
                  <Card>
                    <Card.Img variant="top" src="images/news-img-1.jpg" />
                    <Card.Body>
                      {/* <Card.Text>{t('card_txt_one')}</Card.Text> */}
                      <Card.Title>{t('card_title_one')}
                      </Card.Title>
                      {/* <Link to={"/news"} className="btn-link">
                      {t('card_read_btn_one')} <i className="bi bi-arrow-up-right"></i>
                      </Link> */}
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Img variant="top" src="images/news-img-2.jpg" />
                    <Card.Body>
                      {/* <Card.Text>{t('card_txt_two')}</Card.Text> */}
                      <Card.Title>{t('card_title_two')}
                      </Card.Title>
                      {/* <Link to={"/news"} className="btn-link">
                      {t('card_read_btn_two')}<i className="bi bi-arrow-up-right"></i>
                      </Link> */}
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Img variant="top" src="images/news-img-3.jpg" />
                    <Card.Body>
                      {/* <Card.Text>{t('card_txt_three')}</Card.Text> */}
                      <Card.Title> {t('card_title_three')}</Card.Title>
                      {/* <Link to={"/news"} className="btn-link">
                      {t('card_read_btn_three')}<i className="bi bi-arrow-up-right"></i>
                      </Link> */}
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Img variant="top" src="images/news-img-4.jpg" />
                    <Card.Body>
                      {/* <Card.Text>{t('card_txt_four')}</Card.Text> */}
                      <Card.Title>{t('card_title_four')}</Card.Title>
                      {/* <Link to={"/news"} className="btn-link">
                      {t('card_read_btn_four')} <i className="bi bi-arrow-up-right"></i>
                      </Link> */}
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Home;
