import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Footer from "../Footer";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Company_profile = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
              <h6>{t('investor_info_title')}</h6>
                <h2>{t('company_profile_title')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/company-profile-banner.jpg" />
      </section>

      {/* ************************************************
     Body Content
    ************************************************ */}
      <section id="financial_summary">
        <Container>
          <Row>
            <Col sm={12}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>{t('presentation')}</th>
                    <th>{t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>PRESENTATION PERSEROAN</td>
                    <td>
                    <Link to={"../company_profile_download/1-company-profile-PTXOLARERCRENERGY.pdf"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>PRESENTATION PT. ASPAL POLIMER EMULSINDO</td>
                    <td>
                    <Link to={"../company_profile_download/2-company-profile_PT-APE.pdf"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>PRESENTATION PT. MODIFIKASI BITUMEN SUMATERA</td>
                    <td>
                    <Link to={"../company_profile_download/3-company-profile-MBS.pdf"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>PRESENTATION PT. APLIKASI BITUMEN SUMATERA</td>
                    <td>
                    <Link to={"../company_profile_download/4-company-profile-PT-ABI-new.pdf"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>PRESENTATION PT. XOLABIT BITUMEN BORNEO</td>
                    <td>
                    <Link to={"#"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>PRESENTATION PT. XOLABIT BITUMEN INDUSTRI</td>
                    <td>
                    <Link to={"#"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>PRESENTATION PT. BUMIRAYA ENERGI HIJAU</td>
                    <td>
                    <Link to={"../company_profile_download/7-company-profile-PT-BEH-V.0.pdf"} className="btn" target="_blank">
                    <i className="bi bi-download"></i> {t('download')}
                  </Link>
                    </td>
                  </tr>

                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Company_profile;
