import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";
const Company_structure = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('company_structure_about')}</h6>
                <h2>{t('company_structure')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/about-sub-banner.jpg" />
      </section>

      {/* ************************************************
    Customers Logo
    ************************************************ */}
      <section id="customers">
        <Container>
          <Row>
            <Col sm={12}>
              <Image src="images/company-structure-tree.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Company_structure;
