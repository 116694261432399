import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Table from "react-bootstrap/Table";
import Footer from "../Footer";

import { useTranslation } from "react-i18next";

const Announcement = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
              <h6>{t("investor_info_title")}</h6>
                <h2>{t("announcement_title")}</h2>
                
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
    Body Content
    ************************************************ */}
      <section id="announcement">
        <Container>
          <Row>
            <Col sm={12}>
            <div className="no-data-found">
                <Image src="../images/no-data-found.jpg" />
                <h3>{t("no_data_found")}</h3>
                <p>{t("no_data_found_content")}</p>
              </div>
              {/* <Table responsive>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>{t('announcement_table_title')}</th>
                    <th>{t('date')}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Risalah_RUPST_IPPE_2023_ENG</td>
                    <td>TBA</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Risalah_RUPST_IPPE_2023_INA</td>
                    <td>TBA</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Pengumuman_RUPST_IPPE_2023_ENG</td>
                    <td>TBA</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Pengumuman_RUPST_IPPE_2023_INA</td>
                    <td>TBA</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Press_Release_IPO</td>
                    <td>TBA</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table> */}
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Announcement;
