import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css";
import { Autoplay, Navigation } from "swiper";
import { useTranslation } from "react-i18next";


const HeroSlider = () => {
  const { t } = useTranslation(); 
  return (
    <div>
      <Swiper navigation={true} modules={[Navigation, Autoplay]} autoplay={{delay: 5000, disableOnInteraction: false, }} className="home-slider">
        <SwiperSlide className="slider-1">
          <Container fluid>
            <Row>
              <Col sm={12} md={6} lg={6} xl={6} className="text-center">
                <Image src="images/hero-img.png" className="slider-hero" />
              </Col>
              <Col sm={12} md={6} lg={6} xl={5}>
                <h1>
                  <span>{t('construction')}</span> {t('service')}
                </h1>
                <h6>{t('construction_para')}
                </h6>
                <h4>{t('contributing')}</h4>
              </Col>
            </Row>
          </Container>
          <Image src="images/slider-bg-1.jpg" className="slider-bg" />
        </SwiperSlide>

        <SwiperSlide className="slider-2">
          <Container>
            <Row>
              <Col sm={10}>
                <h1>{t('asphalt_heading')}</h1>
                <h6>{t('asphalt_para')}</h6>
                <h4>{t('contributing')}</h4>
              </Col>
            </Row>
          </Container>
          <Image src="images/slider-bg-2.jpg" className="slider-bg" />
        </SwiperSlide>

        <SwiperSlide className="slider-3">
          <Container>
            <Row>
              <Col sm={8}>
                <h1>{t('asphalt_process_heading')}</h1>
                <h6>{t('asphalt_process_para')}</h6>
                <h4>{t('contributing')}</h4>
              </Col>
            </Row>
          </Container>
          <Image src="images/slider-bg-3.jpg" className="slider-bg" />
        </SwiperSlide>
        <SwiperSlide className="slider-4">
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <h1> {t('tranding_heading')}</h1>
                <h6>{t('tranding_para')}</h6>
                <h4>{t('contributing')}</h4>
              </Col>
            </Row>
          </Container>
          <Image src="images/slider-bg-4.png" className="slider-bg" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSlider;
