import "./App.css";

import Home from "./components/Home";
import Footer from "./components/Footer";
import Our_product from "./components/Our_product";
import Our_services from "./components/Our_services";
import Stock_info from "./components/InvestorInformation/Stock_information";
import Financial_summary from "./components/InvestorInformation/Financial_summary";
import Financial_statements from "./components/InvestorInformation/Financial_statements";
import Annual_report from "./components/InvestorInformation/Annual_report";
import Sustainability_report from "./components/InvestorInformation/Sustainability_report";
import Company_profile from "./components/InvestorInformation/Company_profile";
import Prospectus from "./components/InvestorInformation/Prospektus";
import Announcement from "./components/InvestorInformation/Announcement";
import Investor_relations from "./components/InvestorInformation/Investor_relations";
import About_us from "./components/Aboutus/About_us";
import Board_commissioners from "./components/Management/Board_commissioners";
import Board_directors from "./components/Management/Board_directors";
import Customer from "./components/Aboutus/Customer";
import Company_structure from "./components/Aboutus/Company_structure";
import Subsidiary from "./components/Aboutus/Subsidiary";
import Certificate from "./components/Aboutus/Certificate";
import Partners from "./components/Partners.jsx";
import Our_gallery from "./components/Our_gallery.jsx";
import Our_news from "./components/Our_news.jsx";
import Contact_us from "./components/Contact_us.jsx";
import Gms from "./components/Gcg/Gms.jsx";
import Board_of_commissioners from "./components/Gcg/Board_of_commissioners.jsx";
import Board_of_directors from "./components/Gcg/Board_of_directors.jsx";
import Gcg_structure from "./components/Gcg/Gcg_structure.jsx";
import Corporate_secretary from "./components/Gcg/Corporate_secretary.jsx";
import Committees_board_of_commissioners from "./components/Gcg/Committees_board_of_commissioners.jsx";
import Internal_audits from "./components/Gcg/Internal_audits.jsx";
import Gcg_implementation from "./components/Gcg/Gcg_implementation.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";


function App() {
  const lang = localStorage.getItem("language");

  useEffect(()=>{
    if (!lang) {
      localStorage.setItem("language", "en");
    }
  },[])
  
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/" element={<Footer />} />
        <Route exact path="/our-product" element={<Our_product />} />
        <Route exact path="/our-services" element={<Our_services />} />
        <Route exact path="/stock-information" element={<Stock_info />} />
        <Route exact path="/financial-summary" element={<Financial_summary />} />
        <Route exact path="/financial-statements" element={<Financial_statements />} />
        <Route exact path="/annual-report" element={<Annual_report />} />
        <Route exact path="/sustainability-report" element={<Sustainability_report />} />
        <Route exact path="/company-profile" element={<Company_profile />} />
        <Route exact path="/prospectus" element={<Prospectus />} />
        <Route exact path="/announcement" element={<Announcement />} />
        <Route exact path="/investor-relations" element={<Investor_relations />} />
        <Route exact path="/about-us" element={<About_us />} />
        <Route exact path="/board-commissioners" element={<Board_commissioners />} />
        <Route exact path="/board-directors" element={<Board_directors />} />
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/company-structure" element={<Company_structure />} />
        <Route exact path="/subsidiary" element={<Subsidiary />} />
        <Route exact path="/certificate" element={<Certificate />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/our-gallery" element={<Our_gallery />} />
        <Route exact path="/our-news" element={<Our_news />} />
        <Route exact path="/contact-us" element={<Contact_us />} />
        <Route exact path="/gms" element={<Gms />} />
        <Route exact path="/board-of-commissioners" element={<Board_of_commissioners />} />
        <Route exact path="/board-of-directors" element={<Board_of_directors />} />
        <Route exact path="/gcg-structure" element={<Gcg_structure />} />
        <Route exact path="/corporate-secretary" element={<Corporate_secretary />} />
        <Route exact path="/committees-board-of-commissioners" element={<Committees_board_of_commissioners />} />
        <Route exact path="/internal-audits" element={<Internal_audits />} />
        <Route exact path="/gcg-implementation" element={<Gcg_implementation />} />
        
        
      </Routes>
    </Router>
  );
}

export default App;
