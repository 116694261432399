import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../Footer";

const Gcg_structure = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('gcg_structure_heading')}</h6>
                <h2>{t('gcg_structure')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    General Shareholder Meeting
    ************************************************ */}
      <section id="gcg_structure">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="position-relative">
              <Image src="images/gcg-structure.jpg" />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="content-panel">
                <p>{t('gcg_structure_history')}
                </p>
                <Link to={"#"} className="btn-readmore">
                  <i className="bi bi-download"></i> {t('gcg_structure_btn')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ************************************************
    STRUCTURE GCG
    ************************************************ */}
      <section id="customers">
        <Container>
          <Row>
            <Col sm={12}>
              <Image src="images/company-structure-tree.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Gcg_structure;
