import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Footer from "../Footer";
import Table from "react-bootstrap/Table";

import { useTranslation } from "react-i18next";

const Investor_relations = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
                <h6>{t("investor_info_title")}</h6>
                <h2>{t("investor_relations_title")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
    Body Content
    ************************************************ */}
      <section id="investor_relations">
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              {/* <div className="no-data-found">
                <Image src="../images/no-data-found.jpg" />
                <h3>{t("no_data_found")}</h3>
                <p>{t("no_data_found_content")}</p>
              </div> */}

              <Table responsive>
              <tbody>
                <tr>
                  <th>
                    <i className="bi bi-caret-right-square-fill"></i>{" "}
                    {t("corporation_name")}
                  </th>
                  <td>Dinda Oktavia</td>
                </tr>
                <tr>
                  <th>
                    <i className="bi bi-caret-right-square-fill"></i>{" "}
                    {t("corporation_position")}
                  </th>
                  <td>{t("corporation_position_data")}</td>
                </tr>
                <tr>
                  <th>
                    <i className="bi bi-caret-right-square-fill"></i>{" "}
                    {t("telephone_number")}
                  </th>
                  <td> 021 - 22702295</td>
                </tr>
                <tr>
                  <th>
                    <i className="bi bi-caret-right-square-fill"></i> E-mail
                  </th>
                  <td>corsec@xolare.co.id</td>
                </tr>
              </tbody>
            </Table>
            </Col>
            
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Investor_relations;
