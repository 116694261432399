import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();  
  return (
    <div>
      <section id="footer">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={5} xl={4}>
              <div className="content-panel">
                <Image src="images/logo.png" className="footer-logo" />
                <h5>{t('footer-heading')}</h5>
                <p>{t('footer-info')}</p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} xl={{ span: 4, offset: 1 }}>
              <div className="content-panel">
                <h4>{t('contact-heading')}</h4>
                <p>{t('contact-info')}</p>
                <p className="phone">
                {t('phone-text')}<Link to={"tel:021-22702295"}>021 - 22702295</Link>
                </p>
                <p className="email">
                {t('email-text')}{" "}
                  <Link to={"mailto:contact@xolare.co.id"}>
                    corsec@xolare.co.id
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={3} xl={{ span: 2, offset: 1 }}>
              <div className="content-panel">
                <h4>{t('follow-us')}</h4>
                <div className="social-icon">
                  <Link to={"#"}>
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link to={"#"}>
                  <i className="bi bi-twitter"></i>
                  </Link>
                  <Link to={"#"}>
                  <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12}>
               <p className="copy-right"> © 2023 <span>PT XOLARE RCR ENERGY TBK</span> •{t('copyright')}</p>

            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Footer;
