import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import i18n from "i18next";
import { useTranslation } from "react-i18next";


const Navbar_menu = () => {
  const lang = localStorage.getItem("language");
  const [state, setState] = useState(lang)

  const { t } = useTranslation();

  const changeLang = (e) => {
    const val = e.target.value
      setState(val);
      i18n.changeLanguage(val);
      localStorage.setItem('language',val);
  };
  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container fluid className="justify-content-end">
            <Navbar.Toggle />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body className="justify-content-end">
                <Nav>
                  <Nav.Link href="/">{t('menu_home')}</Nav.Link>
                  <NavDropdown
                    title={t("menu_about_us")} 
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/about-us">
                    - {t("menu_about_us")}
                    </NavDropdown.Item>
                    <NavDropdown
                      title={t("menu_management")}
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item href="/board-commissioners">
                      - {t("menu_board_of_commissioners")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/board-directors">
                      - {t("menu_board_of_directors")}
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown.Item href="/customer">- {t("menu_customer")}</NavDropdown.Item>
                    <NavDropdown.Item href="/company-structure">- {t("menu_company_structure")}</NavDropdown.Item>
                    <NavDropdown.Item href="/subsidiary">- {t("menu_subsidiary")}</NavDropdown.Item>
                    <NavDropdown.Item href="/certificate">- {t("menu_certificate")}</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={t("menu_products_services")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/our-product">
                    - {t("menu_our_products")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/our-services">
                   - {t("menu_our_services")}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={t("menu_investor_information")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/stock-information">
                      - {t("menu_stock_information")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/financial-summary">
                      - {t("menu_financial_summary")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/financial-statements">
                      - {t("menu_financial_statements")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/annual-report">
                      - {t("menu_annual_report")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/sustainability-report">
                      - {t("menu_sustainability_report")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/company-profile">
                      - {t("menu_company_profile")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/prospectus">
                      - {t("menu_prospectus")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/announcement">
                      - {t("menu_announcement")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/investor-relations">
                      - {t("menu_investor_relations")}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={t("menu_gcg")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/gms">- {t("menu_gms")}</NavDropdown.Item>
                    <NavDropdown.Item href="/board-of-commissioners">- {t("menu_gcg_board_of_commissioners")}</NavDropdown.Item>
                    <NavDropdown.Item href="/board-of-directors">- {t("menu_board_of_director")}</NavDropdown.Item>
                    <NavDropdown.Item href="/corporate-secretary">- {t("menu_corporate_secretary")}</NavDropdown.Item>
                    <NavDropdown.Item href="/committees-board-of-commissioners">- {t("menu_committees_commissioners")}</NavDropdown.Item>
                    <NavDropdown.Item href="/internal-audits">- {t("menu_internal_audits")}</NavDropdown.Item>
                    <NavDropdown.Item href="/gcg-implementation">- {t("menu_gcg_implemention")}</NavDropdown.Item>
                    {/* <NavDropdown.Item href="/gcg-structure">- Structure GCG</NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title={t("menu_gallery_news")}
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/our-gallery">
                      - {t("menu_our_gallery")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/our-news">
                      - {t("menu_news_updates")}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/partners">{t("menu_partners")}</Nav.Link>
                  <Nav.Link href="/contact-us">{t("menu_contact")}</Nav.Link>
                  <Nav.Link className="me-0">
                    <Form.Select value={state} onChange={changeLang}>
                      <option value="en" >En</option>
                      <option value="id" >ID</option>
                    </Form.Select>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};
export default Navbar_menu;
