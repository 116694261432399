import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/xolare.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Trans_en from "./locales/en/translation_en.json";
import Trans_id from "./locales/id/translation_id.json";

const Languages = ["en", "id"];
const langSelection = localStorage.getItem("language");
const select = langSelection === null ? "en" : langSelection;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: Trans_en,
      id: Trans_id,
    },
    lng: select,
    whitelist: Languages,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
