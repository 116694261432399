import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Committees_board_of_commissioners = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={6} md={6}>
              <div className="content-panel">
                <h6>{t('gcg_committee')}</h6>
                <h2>
                {t('committee')} <br />
                {t('committee_under_director')}
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    Committees Under The Board of Commissioners Info
    ************************************************ */}
      <section id="committees-info">
        <Container>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 10, offset: 1 }}
            >
              <div className="content-panel">
                <h3>
                {t('committees_under_txt')} <span>{t('committees_under_director_txt')}</span>
                </h3>
                <p>{t('committees_under_para')}
                </p>
                <Link to={"#"} className="btn-download">
                  <i className="bi bi-download"></i>{t('file_download_btn')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Committees Photos
    ************************************************ */}
      {/* <section id="committees">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={3} xl={3}>
              <div className="commissioner-card">
                <Image src="images/hendra-railis.jpg" />
                <h4>Hendra Railis</h4>
                <h5>{t('hendra_railis_chairman')}</h5>
              </div>
            </Col>
            <Col sm={12} md={6} lg={5} xl={{ span: 5, offset: 1 }}>
              <div className="content-panel">
                <p>{t('chairman_info_one')} 
                </p>
                <p>{t('chairman_info_two')}
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3} xl={3}>
              <div className="commissioner-card mt-6">
                <Image src="images/ramdani-eka-saputra.jpg" />
                <h4>Ramdani Eka Saputra</h4>
                <h5>{t('ramdani_eka_saputra_member')}</h5>
              </div>
            </Col>
            <Col sm={12} md={6} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }}>
              <div className="commissioner-card">
                <Image src="images/hassan-riswandi.jpg" />
                <h4>Hassan Riswandi</h4>
                <h5>{t('hassan_riswandi_member')}</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* ************************************************
    Responsibilities
    ************************************************ */}
      <section id="responsibilities">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="content-panel">
                <h6>{t('responsibilitie')}</h6>
                <p>
                {t('responsibility_one')}
                </p>
                <p>{t('responsibility_two')}
                </p>
                <p> {t('responsibility_three')}
                </p>
                <p> {t('responsibility_four')}
                </p>
                <p> {t('responsibility_five')}
                </p>
                <p> {t('responsibility_six')}
                </p>
                <p> {t('responsibility_seven')}
                </p>
                <p> {t('responsibility_eight')}
                </p>
                <p>{t('responsibility_nine')}
                </p>
              </div>
            </Col>
            {/* <Col
              sm={12}
              md={5}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
            >
              <Image src="images/responsibilities-img1.png" />
            </Col> */}
          </Row>
        </Container>
      </section>
      {/* <section id="responsibilities">
        <Container>
          <Row>
            <Col sm={12} md={5} lg={5} xl={5}>
              <Image src="images/responsibilities-img2.png" />
            </Col>
            <Col
              sm={12}
              md={7}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
            >
              <div className="content-panel">
                <p> {t('responsibility_six')}
                </p>
                <p> {t('responsibility_seven')}
                </p>
                <p> {t('responsibility_eight')}
                </p>
                <p>{t('responsibility_nine')}
                </p>
              </div>
            </Col>
          </Row>  
        </Container>
      </section> */}

      <Footer></Footer>
    </>
  );
};

export default Committees_board_of_commissioners;
