import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

import Footer from "../Footer";

const Gcg_implementation = () => {
  const { t } = useTranslation();  

  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('implementation')}</h6>
                <h2>{t('gcg_implementation')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    General Shareholder Meeting
    ************************************************ */}
      <section id="gsm">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={7} xl={7}>
              <div className="content-panel mt-0">
                <p>{t('implementation_info')}
                </p>
                <p>{t('implementation_info_one')}</p>
                <p>{t('implementation_info_two')}</p>
                <p>{t('implementation_info_three')}</p>
                <p>{t('implementation_info_four')}</p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="position-relative">
              
              <div className="content-box">
                <h5>{t('gcg_guidelines')}</h5>
                <p>{t('gcg_guidelines_txt')}</p>
              </div>  
              
              <Image src="images/gcg-implementation-img.jpg" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Gcg_implementation;
