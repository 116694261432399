import React from "react"

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";
 
const Gms = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('gcg')}</h6>
                <h2>{t('gms')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    General Shareholder Meeting
    ************************************************ */}
      <section id="gsm">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="position-relative">
              <Image src="images/gms-img.png" />
              <Link to={"#"} className="btn-download">
              <i className="bi bi-download"></i>{t('download_file_btn')}
              </Link>
            </Col>
            <Col sm={12} md={12} lg={6} xl={{ span: 5, offset: 1 }}>
              <div className="content-panel">
                <h3>{t('general_shareholder_meeting')}</h3>
                <p>{t('gms_info')} 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Gms;

