import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Dropdown from 'react-bootstrap/Dropdown';

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Internal_audits = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={6} md={6}>
              <div className="content-panel">
                <h6>{t("gcg_structure_heading")}</h6>
                <h2>Audit Internal</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>
      {/* ************************************************
     Internal Audits Info
    ************************************************ */}
      <section id="internal-audits-info">
        <Container>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 10, offset: 1 }}
            >
              <div className="content-panel">
                <h3>
                  Audits <span>Internal</span>
                </h3>
                <p>{t("audits_internal_intro")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="down-btn">
           <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn-download">
            <i className="bi bi-download"></i> {t("gcg_structure_btn")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="../audit-internal/SK.0001DIR-PIAGAM-AUDIT-INTERNAL-PT.XRE-Tbk.pdf">Download PDF 1</Dropdown.Item>
              <Dropdown.Item href="../audit-internal/SK.0002DIR-PENGANGKATAN-AUDIT-INTERNAL-PT.XRE-Tbk.pdf">Download PDF 2</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </section>
      {/* ************************************************
    Committees Photos
    ************************************************ */}
      <section id="committees">
        <Container>
          <Row>
            {/* <Col sm={12} md={6} lg={3} xl={3}>
              <Image src="images/internal-audits-img3.jpg" />
            </Col> */}
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="content-panel p-4">
                <h4>{t("adrian_nico_title")}</h4>
                <p>{t("adrian_nico_content")}</p>
              </div>
            </Col>
            {/* <Col sm={12} md={6} lg={3} xl={3}>
              <Image src="images/internal-audits-img4.jpg" />
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Committees Photos
    ************************************************ */}
      <section id="quote_panel">
        <Container className="position-relative">
          <Row>
            <Col sm={12} className="px-0">
              <h1>
                {t("audits_internal_quote1")}{" "}
                <span>{t("audits_internal_quote2")}</span> <br />
                {t("audits_internal_quote3")}
              </h1>
            </Col>
          </Row>
        </Container>
        <Image src="images/call-to-action-committees-bg.jpg" />
      </section>

      {/* ************************************************
    Responsibilities
    ************************************************ */}
      <section id="responsibilities">
        <Container>
          <Row>
            <Col sm={12} className="mb-5">
              <h5>{t("audits_internal_responsibilities_content")}</h5>
            </Col>
            <Col sm={12} md={10} lg={10} xl={10}>
              <div className="content-panel">
                <h6>{t("responsibilitie")}</h6>
                <p>{t("responsibilities_list_1")}</p>
                <p>{t("responsibilities_list_2")}</p>
                <p>{t("responsibilities_list_3")}</p>
                <p>{t("responsibilities_list_4")}</p>
                <p>{t("responsibilities_list_5")}</p>
                <p>{t("responsibilities_list_6")}</p>
                <p>{t("responsibilities_list_7")}</p>
                <p>{t("responsibilities_list_8")}</p>
                <p>{t("responsibilities_list_9")}</p>
                <p>{t("responsibilities_list_10")}</p>
                <p>{t("responsibilities_list_11")}</p>
                <p>{t("responsibilities_list_12")}</p>
                <p>{t("responsibilities_list_13")}</p>
                <p>{t("responsibilities_list_14")}</p>
              </div>
            </Col>
            <Col
              sm={12}
              md={5}
              lg={{ span: 5, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
            >
              <Image src="images/internal-audits-img1.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Internal_audits;
