import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Board_directors = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="management-banner">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="position-relative">
              <div className="content-panel">
                <h6>{t('director_management')}</h6>
                <h2>{t('board_of_director')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/mangement-banner.jpg" />
      </section>

      {/* ************************************************
    Member of board
    ************************************************ */}
      <section id="member_of_board">
        <Container>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/bhadaiwi.png" />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Mochamad Bhadaiwi</h5>
                <h6>{t('president_director')}</h6>
                <p>{t('born_in_yogyakarta')}
                </p>
                <p>{t('energi_hijau_investama')}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="member_of_board" className="gray">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Imam Buchairi</h5>
                <h6>{t('imam_director')}</h6>
                <p>{t('imam_director_info')}</p>
                <p>{t('other_positions')}
                </p>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/imam.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="member_of_board">
        <Container>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/elvis.png" />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Elvis Subiantoro</h5>
                <h6>{t('elvis_director')}</h6>
                <p>{t('elvis_director_info')} 
                </p>
                <p>{t('elvis_director_other_info')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="member_of_board" className="gray">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <div className="content-panel">
                <h5>Hasnan Riswandi</h5>
                <h6>{t('hasnan_director')}</h6>
                <p>{t('hasnan_director_info')}</p>
                <p>{t('hasnan_director_other_info')}
                </p>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="text-center position-relative"
            >
              <Image src="images/hasnan.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Board_directors;
