import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Footer from "../Footer";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Financial_statements = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
                <h6>{t("investor_info_title")}</h6>
                <h2>{t("financial_statements_title")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
     Body Content
    ************************************************ */}
      <section id="financial_summary">
        <Container>
          <Row>
            <Col sm={12}>
              <Table responsive>
                <thead>
                  <tr className="text-center">
                    <th>No.</th>
                    <th>{t("year")}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>1</td>
                    <td>
                      <strong>Dec </strong>‐ 2020
                    </td>
                    <td>
                      <Link
                        to={
                          "../financial-statement-download/REPORT-PT-XOLARE-RCR-ENERGY-DAN-EA-31-Des-2020.pdf"
                        }
                        className="btn"
                        target="_blank"
                      >
                        <i className="bi bi-download"></i> {t("download")}
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>
                      <strong>Dec </strong>- 2021
                    </td>
                    <td>
                      <Link
                        to={
                          "../financial-statement-download/REPORT-PT-XOLARE-RCR-ENERGY-DAN-EA-31-Des-2021.pdf"
                        }
                        className="btn"
                        target="_blank"
                      >
                        <i className="bi bi-download"></i> {t("download")}
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>3</td>
                    <td>
                      <strong>Dec</strong>- 2022
                    </td>
                    <td>
                      <Link
                        to={
                          "../financial-statement-download/REPORT-PT-XOLARE-RCR-ENERGY-DAN-EA-31-Des-2022.pdf"
                        }
                        className="btn"
                        target="_blank"
                      >
                        <i className="bi bi-download"></i> {t("download")}
                      </Link>
                    </td>
                  </tr>
                 
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Financial_statements;
