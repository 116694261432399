import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Board_of_commissioners = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('commissioner_gcg')}</h6>
                <h2>{t('board_of_commissioner_gcg')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    Board of Commissioner
    ************************************************ */}
      <section id="board-commissioner">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={8} xl={{ span: 10, offset: 1 }}>
              <div className="content-panel">
                <h3>
                {t('board_of_txt')} <span>{t('commissioner_txt')}</span>
                </h3>
                <p>{t('board_of_commissioner_supervises')} 
                </p>
              </div>
            </Col>
            {/* <Col sm={12} md={12} lg={12} xl={12} className="text-center">
              <Image src="images/board-commissioner-img.jpg" />
            </Col> */}
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Term of Office
    ************************************************ */}
      <section id="term-office">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={5} xl={5}>
              <div className="content-panel">
                <h2>{t('term_of_office')}</h2>
                <p>{t('members_of_the_board_of_commissioners')} 
                </p>

                <Link to={"/about-us"} className="btn-download">
                <i className="bi bi-download"></i> {t('term_of_office_btn')}
                </Link>
              </div>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7}>
              <Image src="images/term-of-office.jpg" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Board_of_commissioners;
