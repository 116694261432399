import React from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h2>{t('partner_title')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/partners.jpg" />
      </section>

      {/* ************************************************
    Partner Logo
    ************************************************ */}
      <section id="partner-logo">
        <Container>
          <Row>
            <Col sm={12}>
              <ListGroup as="ul" className="logos-list">
                <ListGroup.Item as="li">
                  <Image src="images/partners/1.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/2.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/3.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/4.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/5.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/6.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/7.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/8.png" />
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Image src="images/partners/9.png" />
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Partners;
