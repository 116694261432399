import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Stock_info_widget from "./TradingViewWidget";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Stock_information = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
                <h6>{t('investor_info_title')}</h6>
                <h2>{t('stock_information_title')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
    Content Body
    ************************************************ */}
      <section id="stock-widget">
        <Container>
          <Row>
            <Col sm={12}>
              <Stock_info_widget></Stock_info_widget>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Stock_information;
