import React from "react";
import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
// import Table from "react-bootstrap/Table";
// import Button from "react-bootstrap/Button";
import Footer from "../Footer";

import { useTranslation } from "react-i18next";

const Annual_report = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="investor_info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="content-panel">
              <h6>{t('investor_info_title')}</h6>
              <h2>{t('annual_report')}</h2>
               </div>
            </Col>
          </Row>
        </Container>
        <Image src="../images/investor-info-img.jpg" />
      </section>

      {/* ************************************************
     Body Content
    ************************************************ */}
      <section id="financial_summary">
        <Container>
          <Row>
            <Col sm={12}>
            <div className="no-data-found">
                <Image src="../images/no-data-found.jpg" />
                <h3>{t("no_data_found")}</h3>
                <p>{t("no_data_found_content")}</p>
              </div>
              {/* <Table responsive>
                <thead>
                  <tr className="text-center">
                    <th>No.</th>
                    <th>{t("year")}</th>
                    <th>{t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>1</td>
                    <td>2020</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>2021</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>2022</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>2023</td>
                    <td>
                      <Button>
                        <i className="bi bi-download"></i> {t("download")}
                      </Button>{" "}
                    </td>
                  </tr>
                </tbody>
              </Table> */}
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Annual_report;
