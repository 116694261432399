import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Board_of_directors = () => {
  const { t } = useTranslation(); 
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('board_of_director_gcg')}</h6>
                <h2>{t('gcg_director')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    General Shareholder Meeting
    ************************************************ */}
      <section id="gsm">
        <Container>
          <Row>
            {/* <Col sm={12} md={12} lg={6} xl={6} className="position-relative">
              <Image src="images/board-directors.png" />
            </Col> */}
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="content-panel mt-0">
                <h3>{t('gcg_board_director')}</h3>
                <p>{t('management_of_the_Company')}
                </p>
                <p>{t('management_of_the_company_second')} 
                </p>
                <p>{t('management_of_the_company_third')} 
                </p>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Directors Download File
    ************************************************ */}
      <section id="directors-download-file">
        <Container>
          <Row>
            <Col sm={12} className="position-relative">
              <Image src="images/directors-download-file-bg.jpg" />
              <Link to={"#"} className="btn-download">
              <i className="bi bi-cloud-download-fill"></i>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Board_of_directors;
