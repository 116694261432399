import React from "react";
import { Link } from "react-router-dom";
import Navbar_menu from "../components/Navbar_menu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useEffect, useState } from "react";

// import { useTranslation } from "react-i18next";



const Header = () => {
  // Sticky Header
  const [IsHeaderFixed, setIsHeaderFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 40) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Sticky Header End

  // const { t } = useTranslation();
  return (
    <div>
      <header id="header" className={`${IsHeaderFixed ? "sticky-header" : ""}`}>
        <Container fluid>
          <Row>
            <Col xs={8} sm={2}>
              <Link to={"/"}>
                <Image
                  src="images/logo.png"
                  className="head-logo"
                  alt="Xolare logo"
                />
              </Link>
            </Col>
            <Col xs={4} sm={10}>
              <Navbar_menu></Navbar_menu>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
};

export default Header;
