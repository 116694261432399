import React from "react";

import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";


import Footer from "./Footer";

import { useTranslation } from "react-i18next";

const Our_news = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h2>{t("new_page_title")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/news-banner.jpg" />
      </section>

      {/* ************************************************
    Who We Are Section
    ************************************************ */}
      <section id="news_updates">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-1.jpg" />
                <Card.Body>
                  {/* <Card.Text>{t("card_txt_one")}</Card.Text> */}
                  <Card.Title>{t("card_title_one")}</Card.Title>
                  {/* <Link to={"/news"} className="btn-link">
                    {t("card_read_btn_one")}{" "}
                    <i className="bi bi-arrow-up-right"></i>
                  </Link> */}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-2.jpg" />
                <Card.Body>
                  {/* <Card.Text>{t("card_txt_two")}</Card.Text> */}
                  <Card.Title>{t("card_title_two")}</Card.Title>
                  {/* <Link to={"/news"} className="btn-link">
                    {t("card_read_btn_two")}
                    <i className="bi bi-arrow-up-right"></i>
                  </Link> */}
                </Card.Body>
              </Card>
            </Col>

            <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-3.jpg" />
                <Card.Body>
                  {/* <Card.Text>{t("card_txt_three")}</Card.Text> */}
                  <Card.Title> {t("card_title_three")}</Card.Title>
                  {/* <Link to={"/news"} className="btn-link">
                    {t("card_read_btn_three")}
                    <i className="bi bi-arrow-up-right"></i>
                  </Link> */}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-4.jpg" />
                <Card.Body>
                  {/* <Card.Text>{t("card_txt_four")}</Card.Text> */}
                  <Card.Title>{t("card_title_four")}</Card.Title>
                  {/* <Link to={"/news"} className="btn-link">
                    {t("card_read_btn_four")}{" "}
                    <i className="bi bi-arrow-up-right"></i>
                  </Link> */}
                </Card.Body>
              </Card>
            </Col>
            {/* <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-5.jpg" />
                <Card.Body>
                  <Card.Text>SOLA . 02 May 2019</Card.Text>
                  <Card.Title>
                    Inspection of asphalt volume quantity in packages by unit
                    inspection (SUCOFINDO)
                  </Card.Title>
                  <Link to={"/news"} className="btn-link">
                    Read More <i className="bi bi-arrow-up-right"></i>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <Card>
                <Card.Img variant="top" src="images/news-img-6.jpg" />
                <Card.Body>
                  <Card.Text>SOLA . 02 May 2019</Card.Text>
                  <Card.Title>
                    Inspection of asphalt volume quantity in packages by unit
                    inspection (SUCOFINDO)
                  </Card.Title>
                  <Link to={"/news"} className="btn-link">
                    Read More <i className="bi bi-arrow-up-right"></i>
                  </Link>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Our_news;
