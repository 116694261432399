import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Subsidiary = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('subsidiary_about')}</h6>
                <h2>{t('subsidiary')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/about-sub-banner.jpg" />
      </section>

      {/* ************************************************
    Subsidiary
    ************************************************ */}
      <section id="subsidiary">
        <Container>
          <Row>
          <Col sm={12} className="mb-5"><Image src="images/struktur-grup-perusahaan.jpg" /></Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/aspal-logo.jpg" />
                <h4>{t('pt.aspal polimer')}</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address')}</strong>Kampung Sekaran, RT.001/RW.001, Sekaran, Mranak, Kec. Wonosalam, Kabupaten Demak, Jawa Tengah 59571
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since')}</strong> 2017
                    </p>
                  </Col>
                  
                  <Col sm={6}>
                    <p>
                      <strong>{t('type-of-business')}</strong> Industri Pengolahan
                      Aspal Modifikasi
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project-link')}</strong>
                      <Link
                        to={"https://ape-bitumen.co.id"}
                       >
                        http://ape-bitumen.co.id/
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/modifikasi-logo.jpg" />
                <h4>PT. MODIFIKASI BITUMEN SUMATERA</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address_second')}</strong> Jl. Lintas Sumatera Muara Enim -
                      Prabumulih No.08, RT-02 Dusun VI, Desa Dalam, Belimbing,
                      Kec. Gn. Megang, Kabupaten Muara Enim, Sumatera Selatan
                      31352
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since_second')}</strong> 2020
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('type_of_business_second')}</strong> Industri Pengolahan
                      Aspal Modifikasi
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project_link_second')}</strong> <Link
                        to={"https://modifikasibitumen.co.id/en/home-en/"}
                       >
                        https://modifikasibitumen.co.id/
                      </Link> 
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/aplikasi-logo.jpg" />
                <h4>PT Aplikasi Bitumen Indonesia</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address_third')}</strong> Kampung Sekaran, RT.001/RW.001,
                      Sekaran, Mranak, Kec. Wonosalam, Kabupaten Demak, Jawa
                      Tengah 59571
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since_third')}</strong> 2020
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('type_of_business_third')}</strong> Konstruksi Sipil Jalan
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project_link_third')}</strong> <Link
                        to={"https://aplikasibitumen.co.id/en/home-en/"}
                       >
                        https://aplikasibitumen.co.id/
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/xolabit-borneo-logo.jpg" />
                <h4>PT. XOLABIT BITUMEN BORNEO</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address_fourth')}</strong> Jl. Soekarno Hatta No.km. 29,
                      Karya Merdeka, Kec. Samboja, Kabupaten Kutai Kartanegara,
                      Kalimantan Timur 75271
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since_fourth')}</strong> 2022
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('type_of_business_fourth')}</strong> Industri Pengolahan
                      Aspal Modifikasi
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project_link_fourth')}</strong> <Link
                        to={"https://www.xolabit.co.id/"}
                       >
                        https://www.xolabit.co.id/
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/xolabit-industri-logo.jpg" />
                <h4>PT. XOLABIT BITUMEN INDUSTRI</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address_fifth')}</strong> Jl. Semen Indonesia, Dsn. Krajan,
                      Desa/Kelurahan Karangasem, Kec. Jenu, Kab. Tuban, Provinsi
                      Jawa Timur
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since_fifth')}</strong> 2022
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('type_of_business_fifth')}</strong> Perdagangan Besar dan
                      Industri Kemasan Aspal
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project_link_fifth')}</strong> <Link
                        to={"https://www.xolabit.co.id/"}
                       >
                        https://www.xolabit.co.id/
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <div className="subsidiary-panel">
                <Image src="images/bumiraya-logo.jpg" />
                <h4>PT. BUMIRAYA ENERGI HIJAU</h4>
                <Row>
                  <Col sm={12}>
                    <p>
                      <strong>{t('address_sixth')}</strong> Gedung Plaza Simatupang Lt.6, Jl.
                      TB Simatupang Kav. IS No. 01 Pondok Pinang, Kebayoran
                      Lama, Jakarta Selatan 12310
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('since_sixth')}</strong> 2022
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('type_of_business_sixth')}</strong> Instalasi dan Konstruksi
                      Sistem Solar PV
                    </p>
                  </Col>
                  <Col sm={6}>
                    <p>
                      <strong>{t('project_link_sixth')}</strong> <Link
                        to={"http://www.xolare.id/"}
                       >
                        https://www.xolare.id/
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Subsidiary;
