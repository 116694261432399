import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Company_structure = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('certificate_about')}</h6>
                <h2>{t('certificate')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/about-sub-banner.jpg" />
      </section>

      {/* ************************************************
    Subsidiary
    ************************************************ */}
      <section id="certificate">
        <Container>
          <Row>
            <Col sm={12}>
              <Table responsive>
                
                <tbody>
                  <tr>
                    <th>NIB 9120302222979</th>
                    <td>Nomor Induk Berusaha</td>
                  </tr>
                  <tr>
                    <th>NPWP 72.355.348.3-012.000</th>
                    <td>Nomor Pokok Wajib Pajak</td>
                  </tr>
                  <tr>
                    <th>SBU KONSTRUKSI PB-UMKU 912030222297900040001</th>
                    <td>Perizinan Berusaha Untuk Menunjang Kegiatan Usaha</td>
                  </tr>
                  <tr>
                    <th>SBU KONSTRUKSI PB-UMKU 912030222297900090002</th>
                    <td>Perizinan Berusaha Untuk Menunjang Kegiatan Usaha</td>
                  </tr>
                  <tr>
                    <th>SBU KONSTRUKSI PB-UMKU 912030222297900080001</th>
                    <td>Perizinan Berusaha Untuk Menunjang Kegiatan Usaha</td>
                  </tr>
                  <tr>
                    <th>SBU 371.1.3.203.M.1C.3174.716</th>
                    <td>Sertifikat Badan Usaha Bidang Pembangkit Listrik Tenaga Gas Uap</td>
                  </tr>
                  <tr>
                    <th>SBU 372.1.3.201.M.1C.3174.J16</th>
                    <td>Sertifikat Badan Usaha Bidang Pembangkit Listrik Tenaga Uap</td>
                  </tr>
                  <tr>
                    <th>SBU E83.1.8.211.M.1D.3174.G20</th>
                    <td>Sertifikat Badan Usaha Bidang Gardu Induk</td>
                  </tr>
                  <tr>
                    <th>Sertifikat Standar 91203022229790004</th>
                    <td>Perizinan Berusaha Berbasis Risiko</td>
                  </tr>
                  <tr>
                    <th>ISO 9001:2015</th>
                    <td>Quality Management System</td>
                  </tr>
                  <tr>
                    <th>ISO 14001:2015</th>
                    <td>Environmental Management System</td>
                  </tr>
                  <tr>
                    <th>ISO 45001:2018</th>
                    <td>Healt and Safety Management System</td>
                  </tr>
                  <tr>
                    <th>ISO 37001:2016</th>
                    <td>Anti-Bribery Management System</td>
                  </tr>
                  
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Company_structure;
