import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";
import Dropdown from 'react-bootstrap/Dropdown';

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Corporation_secretary = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="gcg">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t("corporation_gcg")}</h6>
                <h2>{t("corporation_secretary")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/gcg-banner.jpg" />
      </section>

      {/* ************************************************
    CORPORATE SECRETARY
    ************************************************ */}
      <section id="corporation-secretary">
        <Container>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="position-relative text-center"
            >
              <Image src="images/gcg-structure.jpg" />
              {/* <Link to={"#"} className="btn-download">
                <i className="bi bi-cloud-arrow-down-fill"></i>
              </Link> */}

              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn-download"
                >
                 <i className="bi bi-cloud-arrow-down-fill"></i> {t("gcg_structure_btn")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="../secretary-company/SK.0003DIR-PIAGAM-SEKRETARIS-PERUSAHAAN-PT.XRE-Tbk.pdf">
                    Download PDF 1
                  </Dropdown.Item>
                  <Dropdown.Item href="../secretary-company/SK.0004DIR-PENGANGKATAN-SEKRETARIS-PERUSAHAAN-PT.XRE-Tbk.pdf">
                    Download PDF 2
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="content-panel">
                <p>{t("corporation_history")}</p>
                <h6 className="mb-4">{t("corporation_brief_history")}</h6>
                <Table responsive>
                  <tbody>
                    <tr>
                      <th>
                        <i className="bi bi-caret-right-square-fill"></i>{" "}
                        {t("corporation_name")}
                      </th>
                      <td>Dinda Oktavia</td>
                    </tr>
                    <tr>
                      <th>
                        <i className="bi bi-caret-right-square-fill"></i>{" "}
                        {t("corporation_position")}
                      </th>
                      <td>{t("corporation_position_data")}</td>
                    </tr>
                    <tr>
                      <th>
                        <i className="bi bi-caret-right-square-fill"></i>{" "}
                        {t("telephone_number")}
                      </th>
                      <td> 021 - 22702295</td>
                    </tr>
                    <tr>
                      <th>
                        <i className="bi bi-caret-right-square-fill"></i> E-mail
                      </th>
                      <td>corsec@xolare.co.id</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    The duties of the Corporate Secretary include: 
    ************************************************ */}
      <section id="corporation-secretary">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="content-panel p-5">
                <h6 className="mt-0">{t("the_duties_of_the_corporate")}</h6>

                <ListGroup className="content-list">
                  <ListGroup.Item> {t("corporate_one")}</ListGroup.Item>
                  <ListGroup.Item>{t("corporate_two")}</ListGroup.Item>
                  <ListGroup.Item>
                    {t("corporate_three")}
                    <ListGroup>
                      <ListGroup.Item>
                        {t("corporate_three_one")}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {t("corporate_three_two")}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {t("corporate_three_three")}
                      </ListGroup.Item>
                      <ListGroup.Item>{t("corporate_four")}</ListGroup.Item>
                    </ListGroup>
                  </ListGroup.Item>
                  <ListGroup.Item>{t("corporate_four")}</ListGroup.Item>
                  <ListGroup.Item>{t("corporate_five")}</ListGroup.Item>
                </ListGroup>
                <p></p>
                <p>{t("corporate_info")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/company-secretary-img2.jpg" className="cover-img" />
      </section>

      <Footer></Footer>
    </>
  );
};

export default Corporation_secretary;
