import React from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const Our_product = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="Product">
        <Container>
          <Row>
            <Col sm={6}>
              <h2>{t("our_product")}</h2>
            </Col>
          </Row>
        </Container>
        <Image src="images/product-banner.jpg" />
      </section>

      {/* ************************************************
    Product and Services
    ************************************************ */}
      <section id="product_services">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={5} xl={5}>
              <Image src="images/qr-code.png" />
            </Col>
            <Col sm={12} md={12} lg={6} xl={{ span: 6, offset: 1 }}>
              <div className="content-panel">
                <p>{t("our_product_info_one")}</p>

                <p>{t("our_product_info_two")}</p>

                <p>{t("our_product_info_three")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Product and Services section two
    ************************************************ */}
      <section id="product_services" className="bg-light">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <div className="content-panel mb-5">
                <p>{t("our_product_info_four")}</p>

                <p>{t("our_product_info_five")}</p>

                <p>{t("our_product_info_six")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <Image src="images/structure.png" />
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    QUALITY ASSURANCE
    ************************************************ */}
      <section id="quality_assurance">
        <Container>
          <Row>
            <Col sm={12} className="position-relative">
              <div className="content-panel">
                <h2>{t('quality_assurance')}</h2>
                <p>{t('quality_assurance_txt_one')}</p>
                <p>{t('quality_assurance_txt_two')}</p>
              </div>
              <Image src="images/quality-assurance.png" className="bg-img" />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Our_product;
