import React from "react";

import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import Footer from "./Footer";

const Contact_us = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h2>{t("contact_title")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/contact-us-banner.jpg" />
      </section>
      {/* ************************************************
    Map  
    ************************************************ */}
      <section id="map">
        <Container fluid className="px-0">
          <Row>
            <Col sm={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.8309400720304!2d106.7762384!3d-6.2859411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1cc03d7834d%3A0x917d4c5d7a55cf4d!2sPlaza%20Simatupang!5e0!3m2!1sen!2sin!4v1689012584143!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Map"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ************************************************
    Contact Information
    ************************************************ */}
      <Container>
        <section id="contact_info">
          <Row>
            <Col sm={12} md={12} lg={4} xl={4} className="d-flex">
              <i className="bi bi-geo-alt"></i>
              <div className="content-panel">
                <h3>{t("contact_us_address")}</h3>
                <p>
                  Gedung Plaza Simatupang Lt.6 Jl. TB Simatupang Kav. IS No. 01
                  Pondok Pinang Kebayoran Lama, Jakarta Selatan, DKI Jakarta
                  12310 - INDONESIA
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="d-flex ps-5">
              <i className="bi bi-telephone"></i>
              <div className="content-panel">
                <h3>{t("contact_us_phone")}</h3>
                <p>
                  <Link to={"tel:021-22702295"}>021 - 22702295</Link>
                </p>
                <p>
                  <Link to={"mailto:contcat@xolare.co.id"}>
                    corsec@xolare.co.id
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="d-flex ps-5">
              <i className="bi bi-clock"></i>
              <div className="content-panel">
                <h3>{t("contact_us_opening_hours")}</h3>
                <p>{t("contact_us_opening_hours_week")}</p>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      {/* ************************************************
    Get  in Touch
    ************************************************ */}
      <section id="contact_form">
        <Container>
          <Row>
            <Col sm={12}>
              <h2>{t("contact_us_get_in_touch")}</h2>
              <p>{t("contact_us_sub_content")}</p>
            </Col>

            <Col sm={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <Form className="mt-5">
                <Row>
                  <Col sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="mb-4" controlId="full_name">
                      <Form.Label>{t("contact_form_fullname")}</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={6}>
                    <Form.Group className="mb-4" controlId="full_name">
                      <Form.Label>{t("contact_form_email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Form.Group className="mb-4" controlId="subject">
                      <Form.Label>{t("contact_form_subject")}</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Form.Group className="mb-4" controlId="msg">
                      <Form.Label>{t("contact_form_message")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder=""
                        style={{ height: "100px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Button
                      variant="primary"
                      className="submit-btn"
                      type="submit"
                    >
                      {t("contact_form_send_btn")}{" "}
                      <i className="bi bi-cursor-fill"></i>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Contact_us;
