import React from "react";
import Header from "./Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const Our_services = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="Product">
        <Container>
          <Row>
            <Col sm={6}>
              <h2>{t('our_services')}</h2>
            </Col>
          </Row>
        </Container>
        <Image src="images/product-banner.jpg" />
      </section>

      {/* ************************************************
    Product and Services
    ************************************************ */}
      <section id="product_services">
        <Container>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={5}
              xl={6}
              className="position-relative text-right"
            >
              <Image src="images/services-img1.png" />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="content-panel">
                <h3>{t('our_services_heading')}</h3>
                <p>{t('our_services_history')}
                </p>
                <p>{t('our_services_xolare_history')} 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
   Services
    ************************************************ */}
      <section id="services-list">
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={6} xl={{ span: 4, offset: 2 }}>
              <div className="content-panel">
                <h3>{t('services')}</h3>
                <ListGroup>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_one')}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_two')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_three')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_four')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_five')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_six')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_seven')}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <i className="bi bi-check-lg"></i> {t('service_eight')}{" "}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col sm={12} md={12} lg={5} xl={6}>
              <Image src="images/services-img2.png" width={"100%"} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* ************************************************
    Services Feature
    ************************************************ */}
      <section id="service_features">
        <Container>
          <Row>
            <Col sm={12} md={3} lg={3} xl={3}>
              <div className="feature-panel">
                <Image src="images/solar1.jpg" />
                <h4>{t('construction_of_solar_pv')}</h4>
                <p>{t('development_of_energy')}</p>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3}>
              <div className="feature-panel">
                <Image src="images/power-plant.png" />
                <h4>{t('steam_generated')}</h4>
                <p>{t('construction_of_power')}</p>
              </div>
            </Col>
            <Col sm={12} md={3} lg={3} xl={3}>
              <div className="feature-panel">
              <Image src="images/civil-building-icon.png" />
                <h4>{t('construction_of_industrial_building')}</h4>
                <p>{t('construction_of_industrial_txt')}</p>
              </div>
            </Col>

            <Col sm={12} md={3} lg={3} xl={3}>
              <div className="feature-panel">
                <Image src="images/mechanical-installation-icon.png" />
                <h4>{t('installation')}</h4>
                <p>{t('installation_info')}</p>
              </div>
            </Col>
           
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Our_services;
