import React from "react";

import Header from "../Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const Subsidiary = () => {
  const { t } = useTranslation();  
  return (
    <>
      <Header></Header>
      {/* ************************************************
    Banner
    ************************************************ */}
      <section id="about-us">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-panel">
                <h6>{t('about_us_menu')}</h6>
                <h2>{t('our_customer')}</h2>
              </div>
            </Col>
          </Row>
        </Container>
        <Image src="images/our-customer-banner.jpg" />
      </section>

      {/* ************************************************
    Customers Logo
    ************************************************ */}
      <section id="customers">
        <Container>
          <Row>
            <Col sm={12}>
              <ListGroup as="ul" className="logos-list">
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-1.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-2.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-3.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-4.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-5.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-6.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-7.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-8.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-9.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-10.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-11.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-12.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-13.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-14.jpg" />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    <Image src="images/customer/brand-15.jpg" />
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Subsidiary;
